@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cute+Font&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cute+Font&family=Gloria+Hallelujah&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cute+Font&family=Gloria+Hallelujah&family=Handlee&family=Silkscreen:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bad+Script&family=Cute+Font&family=Gloria+Hallelujah&family=Handlee&family=Pangolin&family=Silkscreen:wght@400;700&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');





.fredoka-font {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  font-family: "Pangolin", cursive;
  font-weight: 400;
  font-style: normal;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* If you're not using a CSS file, you can insert these styles within a <style> tag in your index.html or equivalent */

.mybg {
  
  background-image: url("./images/grids.png");
  background-color: #000;
  background-size: contain;
  margin: 0;
}

.mybg2 {
  background: radial-gradient(
      circle at bottom left,
      #621512 0%,
      rgba(0, 0, 0, 0) 45%
    ),
    #090808;

  margin: 0;
}
.mybg3 {
  background: radial-gradient(
      circle at top left,
      #0b0e0d 0%,
      rgba(0, 0, 0, 0) 45%
    ),
    #270e0e;
  margin: 0;
}

/* Add this to your CSS file */
.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.translate-x-full {
  transform: translateX(100%);
}

/* Add this to your CSS file */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide-in {
  animation: slideInFromLeft 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}
.slide-in2 {
  animation: slideInFromRight 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

.fade-out {
  animation: fadeOut 1s ease-in-out;
  animation-fill-mode: forwards;
}

.glowing-text {
  color: #c90d0d; /* Deep green color */
  text-shadow: 0 0 5px #fdd7e4, /* Light green glow */ 0 0 8px #fdd7d7,
    /* Light green glow with larger blur */ 0 0 10px #fdd7e4,
    /* Light green glow with even larger blur */ 0 0 12px #fdd7e4; /* Light green glow with the largest blur */
}

.image-container {
  display: inline-block;
  /* Display as inline-block to fit the image size */
  position: relative;
  /* Set the position to relative to contain the pseudo-element */
  background: radial-gradient(circle, cyan 0%, transparent 70%);
  /* Create a radial gradient with gold color */
  opacity: 1;
  /* Adjust the opacity to control the glow intensity */
  border-radius: 50%;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* Make it a circle */
  z-index: -1;
  /* Place it behind the image */
}
